export const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    marginTop: '0',
    height: '100vh',
    background: theme.menu.root(),
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  flex: {
    flex: 1,
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'none',
    boxShadow: 'none',
  },
  appBarShift: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  Toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px 0 116px',
    zIndex: 0,
    background: theme.menu.toolbar,
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    fontFamily: 'Lato, sans-serif',
    ...theme.mixins.toolbar,
  },
  content: {
    width: 800,
    flexGrow: 1,
    backgroundColor: theme.menu.background(),
    paddingLeft: 40,
    // paddingRight: 12,
    marginTop: 65,
    overflowY: 'auto',
  },
  icon: {
    color: 'grey',
  },
  list: {
    width: 100,
    overflowY: 'auto',
    paddingTop: 64,
  },
  item: {
    paddingLeft: 16,
  },
  supportAmount: {
    top: 8,
    right: 30,
    borderRadius: 100,
    backgroundColor: theme.defaults.error,
    fontSize: '12px',
    height: 20,
    width: 20,
    color: 'var(--color-white)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
  menuWrap: {
    background: 'var(--color-sidebar)',
    boxShadow: '0px 2px 23px rgba(0, 0, 0, 0.0593014)',
    zIndex: 9999,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  docLink: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '8px',
    marginBottom: 36,
    '& > span': {
      color: 'var(--color-text-on-primary)',
      fontSize: 12
    }
  },
  listItem: {
    color: '#51E07C',
    marginTop: 8,
    marginBottom: 8,
    fontSize: 12
  },
  title: {
    fontWeight: 'bold',
    lineHeight: 'normal',
    fontSize: 16,
    color: 'var(--color-text-primary)',
  },
  link: {
    color: 'var(--color-text-on-primary)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 75,
    paddingTop: 10,
    position: 'relative',
    '&:hover': {
      background: '#192F39',
      transition: '0.3s all ease-in',
      '& svg path': {
        stroke: '#51E07C',
        background: 'rgba(19,69,186,0.5)',
        transition: '0.3s all ease-in',
      },
    },
  },
  activeLink: {
    color: 'var(--color-text-on-primary)',
    background: '#192F39',
    '& svg path': {
      stroke: '#51E07C'
    },
  },
  backToAnalytics: {
    fontFamily: 'Lato, sans-serif',
    padding: '0',
    margin: '0 24px 0 0',
    cursor: 'pointer',
    fontWeight: 400,
    color: 'var(--color-button-primary)',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  homeButton: {
    fontFamily: 'Lato, sans-serif',
    padding: '0',
    margin: '0 24px 0 0',
    cursor: 'pointer',
    fontWeight: 400,
    color: 'var(--color-button-primary)',
  },
  testButton: {
    color: 'var(--color-text-on-primary)',
    margin: 0,
    fontWeight: 500,
    fontSize: 16,
    textTransform: 'none',
    fontFamily: 'Lato, sans-serif',
  },
})
