/* eslint-disable max-len */
import * as C_ENV from './colorConstantsEnv'

const REPLACE = 'REPLACE'

export const COLOR_TITLE = C_ENV.COLOR_TITLE.includes(REPLACE) ? undefined : C_ENV.COLOR_TITLE
export const COLOR_TEXT_PRIMARY = C_ENV.COLOR_TEXT_PRIMARY.includes(REPLACE) ? undefined : C_ENV.COLOR_TEXT_PRIMARY
export const COLOR_TEXT_SECONDARY = C_ENV.COLOR_TEXT_SECONDARY.includes(REPLACE) ? undefined : C_ENV.COLOR_TEXT_SECONDARY
export const COLOR_TEXT_PLACEHOLDER = C_ENV.COLOR_TEXT_PLACEHOLDER.includes(REPLACE) ? undefined : C_ENV.COLOR_TEXT_PLACEHOLDER
export const COLOR_TEXT_ERROR = C_ENV.COLOR_TEXT_ERROR.includes(REPLACE) ? undefined : C_ENV.COLOR_TEXT_ERROR
export const COLOR_TEXT_SUCCESS = C_ENV.COLOR_TEXT_SUCCESS.includes(REPLACE) ? undefined : C_ENV.COLOR_TEXT_SUCCESS
export const COLOR_TEXT_LINK = C_ENV.COLOR_TEXT_LINK.includes(REPLACE) ? undefined : C_ENV.COLOR_TEXT_LINK
export const COLOR_INPUT_STROKE = C_ENV.COLOR_INPUT_STROKE.includes(REPLACE) ? undefined : C_ENV.COLOR_INPUT_STROKE
export const COLOR_DISABLED_FILL = C_ENV.COLOR_DISABLED_FILL.includes(REPLACE) ? undefined : C_ENV.COLOR_DISABLED_FILL

export const COLOR_PRIMARY = C_ENV.COLOR_PRIMARY.includes(REPLACE) ? undefined : C_ENV.COLOR_PRIMARY
export const COLOR_BUTTON_PRIMARY = C_ENV.COLOR_BUTTON_PRIMARY.includes(REPLACE) ? undefined : C_ENV.COLOR_BUTTON_PRIMARY
export const COLOR_GENERAL_UI = C_ENV.COLOR_GENERAL_UI.includes(REPLACE) ? undefined : C_ENV.COLOR_GENERAL_UI
export const COLOR_SIDEBAR = C_ENV.COLOR_SIDEBAR.includes(REPLACE) ? undefined : C_ENV.COLOR_SIDEBAR
export const COLOR_SIDEBAR_ICON_AND_TEXT = C_ENV.COLOR_SIDEBAR_ICON_AND_TEXT.includes(REPLACE) ? undefined : C_ENV.COLOR_SIDEBAR_ICON_AND_TEXT
export const COLOR_ANALYTICS = C_ENV.COLOR_ANALYTICS.includes(REPLACE) ? undefined : C_ENV.COLOR_ANALYTICS
export const COLOR_TABLE_ROW_PRIMARY = C_ENV.COLOR_TABLE_ROW_PRIMARY.includes(REPLACE) ? undefined : C_ENV.COLOR_TABLE_ROW_PRIMARY
export const COLOR_HOVER_OVERLAY_PRIMARY = C_ENV.COLOR_HOVER_OVERLAY_PRIMARY.includes(REPLACE) ? undefined : C_ENV.COLOR_HOVER_OVERLAY_PRIMARY
export const COLOR_BADGE_PRIMARY = C_ENV.COLOR_BADGE_PRIMARY.includes(REPLACE) ? undefined : C_ENV.COLOR_BADGE_PRIMARY
export const COLOR_PRESSED_OVERLAY_PRIMARY = C_ENV.COLOR_PRESSED_OVERLAY_PRIMARY.includes(REPLACE) ? undefined : C_ENV.COLOR_PRESSED_OVERLAY_PRIMARY
export const COLOR_SHADOW_LOGIN_PRIMARY = C_ENV.COLOR_SHADOW_LOGIN_PRIMARY.includes(REPLACE) ? undefined : C_ENV.COLOR_SHADOW_LOGIN_PRIMARY

export const COLOR_SECONDARY = C_ENV.COLOR_SECONDARY.includes(REPLACE) ? undefined : C_ENV.COLOR_SECONDARY
export const COLOR_BUTTON_SECONDARY = C_ENV.COLOR_BUTTON_SECONDARY.includes(REPLACE) ? undefined : C_ENV.COLOR_BUTTON_SECONDARY
export const COLOR_HOVER_OVERLAY_SECONDARY = C_ENV.COLOR_HOVER_OVERLAY_SECONDARY.includes(REPLACE) ? undefined : C_ENV.COLOR_HOVER_OVERLAY_SECONDARY
export const COLOR_PRESSED_OVERLAY_SECONDARY = C_ENV.COLOR_PRESSED_OVERLAY_SECONDARY.includes(REPLACE) ? undefined : C_ENV.COLOR_PRESSED_OVERLAY_SECONDARY

export const COLOR_LIGHT = C_ENV.COLOR_LIGHT.includes(REPLACE) ? undefined : C_ENV.COLOR_LIGHT
export const COLOR_TEXT_ON_PRIMARY = C_ENV.COLOR_TEXT_ON_PRIMARY.includes(REPLACE) ? undefined : C_ENV.COLOR_TEXT_ON_PRIMARY
export const COLOR_TEXT_ON_SECONDARY = C_ENV.COLOR_TEXT_ON_SECONDARY.includes(REPLACE) ? undefined : C_ENV.COLOR_TEXT_ON_SECONDARY
export const COLOR_HOVER_OVERLAY_LIGHT = C_ENV.COLOR_HOVER_OVERLAY_LIGHT.includes(REPLACE) ? undefined : C_ENV.COLOR_HOVER_OVERLAY_LIGHT
export const COLOR_PRESSED_OVERLAY_LIGHT = C_ENV.COLOR_PRESSED_OVERLAY_LIGHT.includes(REPLACE) ? undefined : C_ENV.COLOR_PRESSED_OVERLAY_LIGHT

export const COLOR_DARK = C_ENV.COLOR_DARK.includes(REPLACE) ? undefined : C_ENV.COLOR_DARK
export const COLOR_HOVER_OVERLAY_DARK = C_ENV.COLOR_HOVER_OVERLAY_DARK.includes(REPLACE) ? undefined : C_ENV.COLOR_HOVER_OVERLAY_DARK

export const IS_WHITE_LABELED_PLATFORM = C_ENV.IS_WHITE_LABELED_PLATFORM.includes(REPLACE) ? false : C_ENV.IS_WHITE_LABELED_PLATFORM.toLowerCase() === 'true'
