import * as C from './colorConstants'

export const rgbToHex = (rgbString) => {
  const rgbArray = rgbString.match(/\d+/g)
  let r = parseInt(rgbArray[0])
  let g = parseInt(rgbArray[1])
  let b = parseInt(rgbArray[2])

  // Ensure values are within valid range
  r = Math.max(0, Math.min(255, r))
  g = Math.max(0, Math.min(255, g))
  b = Math.max(0, Math.min(255, b))

  // Convert each component to hexadecimal and concatenate them
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}

export const getColorAnalytics = () => {
  let RGB = [22, 88, 243]
  if (C.COLOR_ANALYTICS) {
    RGB = C.COLOR_ANALYTICS.split(', ')
  } else if (C.COLOR_PRIMARY) {
    RGB = C.COLOR_PRIMARY.split(', ')
  }
  return {
    R: RGB[0],
    G: RGB[1],
    B: RGB[2]
  }
}

export const getColorButtonPrimary = () => {
  let RGB = [22, 88, 243]
  if (C.COLOR_BUTTON_PRIMARY) {
    RGB = C.COLOR_BUTTON_PRIMARY.split(', ').map(x => parseInt(x))
  } else if (C.COLOR_PRIMARY) {
    RGB = C.COLOR_PRIMARY.split(', ').map(x => parseInt(x))
  }
  return `rgb(${RGB[0]}, ${RGB[1]}, ${RGB[2]})`
}

export const getColorButtonPrimary50 = () => {
  let RGB = [22, 88, 243]
  if (C.COLOR_BUTTON_PRIMARY) {
    RGB = C.COLOR_BUTTON_PRIMARY.split(', ').map(x => parseInt(x))
  } else if (C.COLOR_PRIMARY) {
    RGB = C.COLOR_PRIMARY.split(', ').map(x => parseInt(x))
  }
  return `rgba(${RGB[0]}, ${RGB[1]}, ${RGB[2]}, 0.5)`
}

export const getColorAnalytics75 = () => {
  let RGB = [22, 88, 243]
  if (C.COLOR_ANALYTICS) {
    RGB = C.COLOR_ANALYTICS.split(', ').map(x => parseInt(x))
  } else if (C.COLOR_PRIMARY) {
    RGB = C.COLOR_PRIMARY.split(', ').map(x => parseInt(x))
  }
  return `rgba(${RGB[0]}, ${RGB[1]}, ${RGB[2]}, 0.75)`
}

export const getColorAnalytics40 = () => {
  let RGB = [22, 88, 243]
  if (C.COLOR_ANALYTICS) {
    RGB = C.COLOR_ANALYTICS.split(', ').map(x => parseInt(x))
  } else if (C.COLOR_PRIMARY) {
    RGB = C.COLOR_PRIMARY.split(', ').map(x => parseInt(x))
  }
  return `rgba(${RGB[0]}, ${RGB[1]}, ${RGB[2]}, 0.4)`
}

export const getColorButtonSecondary50 = () => {
  let RGB = [90, 152, 247]
  if (C.COLOR_BUTTON_SECONDARY) {
    RGB = C.COLOR_BUTTON_SECONDARY.split(', ').map(x => parseInt(x))
  } else if (C.COLOR_SECONDARY) {
    RGB = C.COLOR_SECONDARY.split(', ').map(x => parseInt(x))
  }
  return `rgba(${RGB[0]}, ${RGB[1]}, ${RGB[2]}, 0.5)`
}

export const getColorGeneralUi = () => {
  let RGB = [22, 88, 243]
  if (C.COLOR_GENERAL_UI) {
    RGB = C.COLOR_GENERAL_UI.split(', ').map(x => parseInt(x))
  } else if (C.COLOR_PRIMARY) {
    RGB = C.COLOR_PRIMARY.split(', ').map(x => parseInt(x))
  }
  return `rgb(${RGB[0]}, ${RGB[1]}, ${RGB[2]})`
}

export const getColorHoverOverlayPrimary = () => {
  let RGBA = [22, 88, 243, 0.1]
  if (C.COLOR_HOVER_OVERLAY_PRIMARY) {
    RGBA = C.COLOR_HOVER_OVERLAY_PRIMARY.split(', ').map(x => parseInt(x))
  } else if (C.COLOR_PRIMARY) {
    RGBA = C.COLOR_PRIMARY.split(', ').map(x => parseInt(x))
    RGBA.push(0.1)
  }
  return `rgba(${RGBA[0]}, ${RGBA[1]}, ${RGBA[2]}, ${RGBA[3]})`
}

export const getColorGeneralUiRGB = () => {
  let RGB = [22, 88, 243]
  if (C.COLOR_GENERAL_UI) {
    RGB = C.COLOR_GENERAL_UI.split(', ')
  } else if (C.COLOR_PRIMARY) {
    RGB = C.COLOR_PRIMARY.split(', ')
  }
  return {
    r: parseInt(RGB[0]),
    g: parseInt(RGB[1]),
    b: parseInt(RGB[2])
  }
}

export const getSidebarIconColorRGB = () => {
  let RGBA = [255, 255, 255, 0.4]
  let SIDEBAR_RGB = [22, 88, 243]

  if (C.COLOR_SIDEBAR) {
    SIDEBAR_RGB = C.COLOR_SIDEBAR.split(', ')
  } else if (C.COLOR_PRIMARY) {
    SIDEBAR_RGB = C.COLOR_PRIMARY.split(', ')
  }

  if (C.COLOR_SIDEBAR_ICON_AND_TEXT) {
    RGBA = C.COLOR_SIDEBAR_ICON_AND_TEXT.split(', ')
  } else if (C.COLOR_TEXT_ON_PRIMARY) {
    RGBA = C.COLOR_TEXT_ON_PRIMARY.split(', ')
  }

  const alpha = RGBA[3] ? RGBA[3] : 0.4

  const NEW_RGB = {
    r: RGBA[0] * alpha + SIDEBAR_RGB[0] * (1 - alpha),
    g: RGBA[1] * alpha + SIDEBAR_RGB[1] * (1 - alpha),
    b: RGBA[2] * alpha + SIDEBAR_RGB[2] * (1 - alpha)
  }

  return `rgb(${NEW_RGB.r}, ${NEW_RGB.g}, ${NEW_RGB.b})`
}

export const getLoginSVGBottomGradientColorRGB = () => {
  const RGBA = [255, 255, 255, 0.35]
  let GENERAL_UI_RGB = [22, 88, 243]
  if (C.COLOR_GENERAL_UI) {
    GENERAL_UI_RGB = C.COLOR_GENERAL_UI.split(', ')
  } else if (C.COLOR_PRIMARY) {
    GENERAL_UI_RGB = C.COLOR_PRIMARY.split(', ')
  }

  const alpha = RGBA[3]

  const NEW_RGB = {
    r: RGBA[0] * alpha + GENERAL_UI_RGB[0] * (1 - alpha),
    g: RGBA[1] * alpha + GENERAL_UI_RGB[1] * (1 - alpha),
    b: RGBA[2] * alpha + GENERAL_UI_RGB[2] * (1 - alpha)
  }

  return `rgb(${NEW_RGB.r}, ${NEW_RGB.g}, ${NEW_RGB.b})`
}
