export const COLOR_TITLE = 'REPLACE_COLOR_TITLE'
export const COLOR_TEXT_PRIMARY = 'REPLACE_COLOR_TEXT_PRIMARY'
export const COLOR_TEXT_SECONDARY = 'REPLACE_COLOR_TEXT_SECONDARY'
export const COLOR_TEXT_PLACEHOLDER = 'REPLACE_COLOR_TEXT_PLACEHOLDER'
export const COLOR_TEXT_ERROR = 'REPLACE_COLOR_TEXT_ERROR'
export const COLOR_TEXT_SUCCESS = 'REPLACE_COLOR_TEXT_SUCCESS'
export const COLOR_TEXT_LINK = 'REPLACE_COLOR_TEXT_LINK'
export const COLOR_INPUT_STROKE = 'REPLACE_COLOR_INPUT_STROKE'
export const COLOR_DISABLED_FILL = 'REPLACE_COLOR_DISABLED_FILL'

export const COLOR_PRIMARY = 'REPLACE_COLOR_PRIMARY'
export const COLOR_BUTTON_PRIMARY = 'REPLACE_COLOR_BUTTON_PRIMARY'
export const COLOR_GENERAL_UI = 'REPLACE_COLOR_GENERAL_UI'
export const COLOR_SIDEBAR = 'REPLACE_COLOR_SIDEBAR'
export const COLOR_SIDEBAR_ICON_AND_TEXT = 'REPLACE_COLOR_SIDEBAR_ICON_AND_TEXT'
export const COLOR_ANALYTICS = 'REPLACE_COLOR_ANALYTICS'
export const COLOR_TABLE_ROW_PRIMARY = 'REPLACE_COLOR_TABLE_ROW_PRIMARY'
export const COLOR_HOVER_OVERLAY_PRIMARY = 'REPLACE_COLOR_HOVER_OVERLAY_PRIMARY'
export const COLOR_BADGE_PRIMARY = 'REPLACE_COLOR_BADGE_PRIMARY'
export const COLOR_PRESSED_OVERLAY_PRIMARY = 'REPLACE_COLOR_PRESSED_OVERLAY_PRIMARY'
export const COLOR_SHADOW_LOGIN_PRIMARY = 'REPLACE_COLOR_SHADOW_LOGIN_PRIMARY'

export const COLOR_SECONDARY = 'REPLACE_COLOR_SECONDARY'
export const COLOR_BUTTON_SECONDARY = 'REPLACE_COLOR_BUTTON_SECONDARY'
export const COLOR_HOVER_OVERLAY_SECONDARY = 'REPLACE_COLOR_HOVER_OVERLAY_SECONDARY'
export const COLOR_PRESSED_OVERLAY_SECONDARY = 'REPLACE_COLOR_PRESSED_OVERLAY_SECONDARY'

export const COLOR_LIGHT = 'REPLACE_COLOR_LIGHT'
export const COLOR_TEXT_ON_PRIMARY = 'REPLACE_COLOR_TEXT_ON_PRIMARY'
export const COLOR_TEXT_ON_SECONDARY = 'REPLACE_COLOR_TEXT_ON_SECONDARY'
export const COLOR_HOVER_OVERLAY_LIGHT = 'REPLACE_COLOR_HOVER_OVERLAY_LIGHT'
export const COLOR_PRESSED_OVERLAY_LIGHT = 'REPLACE_COLOR_PRESSED_OVERLAY_LIGHT'

export const COLOR_DARK = 'REPLACE_COLOR_DARK'
export const COLOR_HOVER_OVERLAY_DARK = 'REPLACE_COLOR_HOVER_OVERLAY_DARK'

export const IS_WHITE_LABELED_PLATFORM = 'REPLACE_IS_WHITE_LABELED_PLATFORM'
